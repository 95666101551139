<template>
  <div class="side-menu">
    <i class="menu-btn" @click="menuShow"></i>

    <van-popup
      v-model="popupShow"
      :close-on-click-overlay="false"
      :style="{ background: 'transparent' }"
    >
      <div class="popup-body">
        <ul class="menu-items">
          <li @click="goMarkets">
            <i></i>
            <s>返回首页</s>
          </li>
          <li @click="logout">
            <i></i>
            <s>退出登录</s>
          </li>
        </ul>

        <i class="close-btn" @click="closeMenu"></i>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from 'vant'
export default {
  name: '',
  components: {
    [Popup.name]: Popup
  },
  data () {
    return {
      popupShow: false
    }
  },
  methods: {
    menuShow () {
      this.popupShow = true
    },
    closeMenu () {
      this.popupShow = false
    },
    goMarkets () {
      this.popupShow = false
      this.$router.push({ name: 'Index' })
    },
    logout () {
      this.popupShow = false
      localStorage.clear()
      this.st.user.logout()
      this.st.user.auth()
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
