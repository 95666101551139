import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import StApp from 'st-ud-sdk/dist/app'
import { Config } from './utils/config'

// 样式表
import './assets/css/reset.less'
import 'vant/lib/index.css'
import './assets/css/index.less'

// 数据接口
import { services } from './api/index'
Vue.prototype.$api = services

// 用户目录 2.0
const st = new StApp({
  appID: Config.wechatID, // 微信公众号ID或支付宝公众号ID
  clientID: Config.clientID, // 项目ID
  baseURL: Config.user2ApiUrl + '/v2/users', // api 接口地址
  mode: '', // 显示风格 默认-普通模式 dark-暗黑模式
  redirectUri: Config.redirectUri // 获取授权码回调地址
})
Vue.prototype.st = st

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
