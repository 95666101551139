const redirectUris = [
  'http://localhost:9001',
  'http://testing.wholesaler.entry.smartracing.cn', // 测试环境
  'https://wholesaler.entry.smartracing.cn' // 正式环境
]

export const Config = {

  // 溯源 api 地址
  // 测试环境：https://demo.smartracing.cn
  // 正式环境：https://api.smartracing.cn
  baseApiUrl: 'https://api.smartracing.cn',

  // 用户目录2.0 api 地址
  // 测试环境：https://testing.smartracing.cn
  // 正式环境：https://api.smartracing.cn
  user2ApiUrl: 'https://api.smartracing.cn',

  // 食神通 api 地址
  // 测试环境：https://testing.smartracing.cn
  // 正式环境：https://devops.smartracing.cn
  base2ApiUrl: 'https://devops.smartracing.cn',

  // 益农 api 地址
  // 测试环境：http://testapi.gdynyp.com
  // 正式环境：https://api.gdynyp.com
  shopApiUrl: 'https://api.gdynyp.com',

  clientID: '5ff569c2ff07f14dee67dd0f', // 项目 ID
  wechatID: '', // 微信公众号ID

  redirectUri: redirectUris[2] // 用户目录登录授权回调地址
}
