<template>
  <div class="layout">
    <side-menu />
    <router-view />
  </div>
</template>

<script>
import SideMenu from '../menu/side/Index'
export default {
  name: 'Layout',
  components: {
    SideMenu
  },
  data () {
    return {}
  }
}
</script>
