import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../components/layout/Index'
import { rolesGrade } from '../utils/roles'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/login',
    children: [
      /* ========================================
       * 批发市场进场
       * ======================================== */
      {
        path: 'markets',
        name: 'Markets',
        component: () => import('../views/markets/Index'),
        meta: {
          title: '市场列表'
        }
      },
      {
        path: 'shop/list',
        name: 'ShopList',
        component: () => import('../views/shops/list/Index'),
        meta: {
          title: '商家列表'
        }
      },
      {
        path: 'shop/management',
        name: 'Management',
        component: () => import('../views/shops/management/Index'),
        meta: {
          title: '店铺管理'
        }
      },
      {
        path: 'entry/register',
        name: 'EntryRegister',
        component: () => import('../views/entrys/register/Index'),
        meta: {
          title: '进场登记'
        }
      },
      {
        path: '/entry/meat',
        name: 'EntryMeat',
        component: () => import('../views/entrys/meat/Index'),
        meta: {
          title: '肉类进场'
        }
      },
      {
        path: 'entry/vegetables',
        name: 'EntryVegetables',
        component: () => import('../views/entrys/vegetables/Index'),
        meta: {
          title: '蔬菜进场'
        }
      },
      {
        path: 'entry/selectKind',
        name: 'SelectKind',
        component: () => import('../views/entrys/selectKind/Index'),
        meta: {
          title: '选择产品种类'
        }
      },
      {
        path: 'goods/sell',
        name: 'GoodSell',
        component: () => import('../views/goods/sell/Index'),
        meta: {
          title: '商品上架'
        }
      },
      {
        path: 'goods/goods',
        name: 'Goods',
        component: () => import('../views/goods/sell/Goods'),
        meta: {
          title: '搜索商品'
        }
      },
      /* ========================================
       * 餐厅食材入库
       * ======================================== */
      {
        path: 'restaurant/list',
        name: 'Restaurants',
        component: () => import('../views/restaurants/list/Index'),
        meta: {
          title: '餐厅列表'
        }
      },
      {
        path: 'restaurant/details',
        name: 'RestaurantDetails',
        component: () => import('../views/restaurants/details/Index'),
        meta: {
          title: '餐厅管理'
        },
        children: [
          {
            path: 'entryRecord',
            name: 'EntryRecord',
            component: () =>
              import('../views/restaurants/details/record/Index'),
            meta: {
              title: '录入记录'
            }
          },
          {
            path: 'supplierList',
            name: 'SupplierList',
            component: () =>
              import('../views/restaurants/details/supplier/Index'),
            meta: {
              title: '供应商列表'
            }
          }
        ]
      },
      {
        path: 'suppliers/add',
        name: 'AddSuppliers',
        component: () => import('../views/restaurants/suppliers/add/Index'),
        meta: {
          title: '新增供应商'
        }
      },
      {
        path: 'food/list',
        name: 'FoodList',
        component: () => import('../views/restaurants/foods/list/Index'),
        meta: {
          title: '食材列表'
        }
      },
      {
        path: 'food/add',
        name: 'AddFood',
        component: () => import('../views/restaurants/foods/add/Index'),
        meta: {
          title: '新增/编辑食材'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/index/Index'),
    meta: {
      title: '首页'
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const userRoles = JSON.parse(localStorage.getItem('user_roles'))
  document.title = to.meta.title
  if (to.name !== 'Login') {
    rolesGrade(userRoles) < 0 ? next({ name: 'Login' }) : next()
  } else {
    next()
  }
})

export default router
