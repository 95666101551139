import axios from 'axios'
import { Toast } from 'vant'
import { Config } from '../utils/config'

// axios 相关配置
const instance = axios.create({
  timeout: 10000
})
instance.interceptors.request.use(config => {
  Toast.loading({
    message: '加载中...',
    duration: 0,
    forbidClick: true
  })
  return config
})
instance.interceptors.response.use(
  response => {
    Toast.clear()
    return response
  },
  error => {
    Toast('网络异常')
    return Promise.reject(error)
  }
)

// 定义请求方法
function $get (url, data = {}) {
  return instance.get(url, { params: data })
}
function $post (url, data = {}) {
  return instance.post(url, data)
}
function $put (url, data = {}) {
  return instance.put(url, data)
}
function $delete (url, data, headers) {
  return instance.delete(url, { params: data, headers })
}

class Services {
  users = {
    roles: data => $get(`${Config.user2ApiUrl}/v2/users/roles/detail/user/roles`, data) // 获取用户角色
  }

  markets = {
    list: data => $get(`${Config.baseApiUrl}/v1/users1/markets/active-markets`, data) // 获取市场列表
  }

  shops = {
    list: data => $get(`${Config.shopApiUrl}/sazs/wholesaler/goods/source/shops`, data) // 获取店铺列表
  }

  entry = {
    list: data => $get(`${Config.shopApiUrl}/sazs/wholesaler/goods/source-list`, data), // 查询某个市场下的进场信息
    add: data => $post(`${Config.shopApiUrl}/sazs/wholesaler/goods/source`, data), // 溯源信息进场登记
    goods: data => $get(`${Config.shopApiUrl}/sazs/wholesaler/goods/source/goods`, data), // 分页查询商户批发商品(进场使用)
    sourceInfo: data => $get(`${Config.shopApiUrl}/sazs/wholesaler/goods/source/${data.cart_source_id}`), // 获取某次进场登记溯源信息
    update: data => $put(`${Config.shopApiUrl}/sazs/wholesaler/goods/source/${data.cart_source_id}`, data.data), // 获取某次进场登记溯源信息
    uploaders: data => $post(`${Config.shopApiUrl}/uploaders`, data) // 图片上传
  }

  restaurant = {
    list: data => $get(`${Config.base2ApiUrl}/v1/restaurant/companies`) // 获取餐厅列表
  }

  supplier = {
    list: data => $get(`${Config.base2ApiUrl}/v1/catalog-go/suppliers`, data), // 获取供应商列表
    foodList: data => $get(`${Config.base2ApiUrl}/v1/catalog-go/supplys/pro`, data), // 获取供应商在售食材
    categoryList: data => $get(`${Config.base2ApiUrl}/v1/catalog-go/categories/`), // 获取食材分类列表
    addFood: data => $post(`${Config.base2ApiUrl}/v1/catalog-go/supplys/pro`, data), // 添加食材
    delFood: data => $delete(
      `${Config.base2ApiUrl}/v1/catalog-go/supplys/pro`,
      data,
      {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    ), // 删除食材
    editFood: data => $put(`${Config.base2ApiUrl}/v1/catalog-go/supplys/pro`, data), // 编辑食材
    recordList: data => $get(`${Config.base2ApiUrl}/v1/catalog-go/stockin/day`, data), // 按时间降序查询入库记录
    unitList: data => $get(`${Config.base2ApiUrl}/v1/catalog-go/units/`, data), // 获取单位列表接口
    addRecord: data => $post(`${Config.base2ApiUrl}/v1/catalog-go/stocks/`, data), // 食材入库
    recordDetail: data => $get(`${Config.base2ApiUrl}/v1/catalog-go/stockin`, data), // 查询入库记录详情
    editRecord: data => $put(`${Config.base2ApiUrl}/v1/catalog-go/stockin`, data) // 编辑入库记录详情
  }

  goods = {
    goodsList: data => $get(`${Config.shopApiUrl}/sazs/wholesaler/goods`, data), // 获取商家的商品列表
    category: data => $get(`${Config.shopApiUrl}/members/shops/market/typethree`, data), // 获取商品分类
    up: (data, action) => $post(`${Config.shopApiUrl}/sazs/wholesaler/goods/up-down/${action}`, data), // 上架
    uploader: data => $post(`${Config.shopApiUrl}/uploaders`, data), // 上传
    getGoods: data => $get('https://api.smartracing.cn/v1/catalog/products/search', data),
    cate: data => $get(`${Config.shopApiUrl}/members/shops/market/typethree/${data}`),
    goodDetail: data => $get(`https://api.smartracing.cn/v1/catalog/products/${data}/detail`)
  }
}

export const services = new Services()
