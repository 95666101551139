import _ from 'lodash'

const roles = [
  {
    name: 'Admin', // 超级管理员
    grade: 1,
    routers: []
  }
]

const rolesGrade = function (name) {
  const n = _.findIndex(roles, item => item.name === name)
  if (n >= 0) {
    return roles[n].grade
  } else {
    return -1
  }
}

export {
  rolesGrade
}
