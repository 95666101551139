import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 用户信息
    userInfo: JSON.parse(localStorage.getItem('user_info')) || {},
    // 用户角色
    userRoles: JSON.parse(localStorage.getItem('user_roles')) || '',
    // 市场列表
    marketItems: [],
    // 店铺列表
    shopItems: [],
    // 车辆信息
    sourceInfo: {},
    // 货物信息
    sourceGoods: {}
  },
  mutations: {
    SET_USER_INFO  (state, data) {
      state.userInfo = data
      localStorage.setItem('user_info', JSON.stringify(data))
    },
    SET_USER_ROLES (state, data) {
      state.userRoles = data
      localStorage.setItem('user_roles', JSON.stringify(data))
    },
    SET_MARKET_ITEMS (state, items) {
      state.marketItems = items
    },
    SET_SHOP_ITEMS (state, items) {
      state.shopItems = items
    },
    SET_SOURCE_INFO (state, data) {
      state.sourceInfo = data
    },
    SET_SOURCE_GOODS (state, data) {
      state.sourceGoods = data
    }
  },
  actions: {
    // 获取用户信息
    setUserInfo (context, data) {
      context.commit('SET_USER_INFO', data)
    },
    // 获取用户角色
    setUserRoles (context, data) {
      context.commit('SET_USER_ROLES', data)
    },
    // 设置市场列表
    setMarketItems (context, data) {
      context.commit('SET_MARKET_ITEMS', data)
    },
    // 设置店铺列表
    setShopItems (context, data) {
      context.commit('SET_SHOP_ITEMS', data)
    },
    // 设置溯源车辆信息
    setSourceInfo (context, data) {
      context.commit('SET_SOURCE_INFO', data)
    },
    // 设置溯源车辆信息
    setSourceGoods (context, data) {
      context.commit('SET_SOURCE_GOODS', data)
    }
  },
  modules: {}
})
